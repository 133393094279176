var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pb-0"},[_c('h3',[_vm._v("Tipo de actividad")]),_c('p',{staticClass:"body-2"},[_vm._v("Puedes seleccionar como máximo tres tipos diferentes de actividad pero debes seleccionar al menos una.")]),_c('validation-provider',{attrs:{"name":"Tipo de actividad","vid":"companyActivityTypes","rules":{minSize: 1, maxSize: 3}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((_vm.activityTypes),function(activityType,index){return _c('v-checkbox',{key:activityType.id,staticClass:"ml-2",attrs:{"multiple":"","rules":errors,"color":"primary","hide-details":index !== _vm.activityTypes.length - 1,"value":activityType.id,"label":activityType.name},model:{value:(_vm.model.companyActivityTypes),callback:function ($$v) {_vm.$set(_vm.model, "companyActivityTypes", $$v)},expression:"model.companyActivityTypes"}})})}}])})],1),(_vm.showCompanyActivityTypeDescription)?_c('div',{staticClass:"col-6 pt-0 pb-0"},[_c('validation-provider',{attrs:{"vid":"companyActivityTypeDescription","name":"¿Cúal?","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-0 pl-2",attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"¿Cúal?","required":""},model:{value:(_vm.model.companyActivityTypeDescription),callback:function ($$v) {_vm.$set(_vm.model, "companyActivityTypeDescription", $$v)},expression:"model.companyActivityTypeDescription"}})]}}],null,false,2425275107)})],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Categorías y segmentos")]),_c('p',{staticClass:"body-2"},[_vm._v("Selecciona las categorías y de cada una de ellas los segmentos a los que corresponden.")])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('p',{staticClass:"font-bold pb-0 mb-0"},[_vm._v("Categorías*")]),_c('p',{staticClass:"body-2"},[_vm._v("Puedes seleccionar como máximo tres categorías pero debes seleccionar al menos una.")]),_c('validation-provider',{attrs:{"name":"Categorias","vid":"companyCategories","rules":{minSize: 1, maxSize: 3}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((_vm.categories),function(category,index){return _c('v-checkbox',{key:category.id,staticClass:"ml-2",attrs:{"multiple":"","color":"primary","hide-details":index !== _vm.categories.length - 1,"value":category.id,"label":category.name,"rules":errors},model:{value:(_vm.model.companyCategories),callback:function ($$v) {_vm.$set(_vm.model, "companyCategories", $$v)},expression:"model.companyCategories"}})})}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('p',{staticClass:"font-bold pb-0 mb-0"},[_vm._v("Segmentos*")]),_c('p',{staticClass:"body-2"},[_vm._v("Puedes seleccionar como máximo 10 segmentos por categoría pero debes seleccionar al menos uno.")]),_vm._l((_vm.categories),function(category,indexCategory){return _c('div',{key:'cat-' + category.id,staticClass:"row"},[(_vm.showSegment(category.id))?_c('div',[(category.segments.length)?_c('validation-provider',{attrs:{"name":"Segmento","vid":'companySegments' + category.id,"rules":{minSize: 1, maxSize: 10}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(category.segments.length)?_c('div',{staticClass:"col-12 pb-0"},[_c('h4',[_vm._v(_vm._s(category.name))])]):_vm._e(),_c('div',{staticClass:"col-12 mx-2 pt-0 pb-0"},_vm._l((category.segments),function(segment,index){return _c('v-checkbox',{key:'seg-' + segment.id,attrs:{"multiple":"","color":"primary","hide-details":index !== category.segments.length - 1,"value":segment.id,"error-messages":errors,"label":segment.name},model:{value:(_vm.model.companySegments[indexCategory]),callback:function ($$v) {_vm.$set(_vm.model.companySegments, indexCategory, $$v)},expression:"model.companySegments[indexCategory]"}})}),1)]}}],null,true)}):_vm._e()],1):_vm._e()])})],2),(_vm.showCompanyCategoryDescription)?_c('div',{staticClass:"col-6 pt-0 pb-0"},[_c('validation-provider',{attrs:{"vid":"showCompanyCategoryDescription","name":"¿Cúal?","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-0 pl-2",attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"¿Cúal?","required":""},model:{value:(_vm.model.companyCategoryDescription),callback:function ($$v) {_vm.$set(_vm.model, "companyCategoryDescription", $$v)},expression:"model.companyCategoryDescription"}})]}}],null,false,148271238)})],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-0 pb-0"},[_c('p',{staticClass:"body-2"},[_vm._v("A continuación agrega hasta 10 marcas que tu empresa exhibirá en la Feria. Para agregar una marca escríbela en el cuadro inferior y haz clic en el botón \"Agregar\". Para eliminar o editar una marca, haz clic sobre ella en la lista.")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"font-bold"},[_vm._v("Marcas que exhibirá la empresa (máximo 10).")]),_vm._l((_vm.model.companyBrands),function(companyBrand,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-8 col-md-6 pt-0 pb-0"},[_c('validation-provider',{attrs:{"name":"Nombre marca","vid":'companyBrand'+index,"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre marca","required":""},model:{value:(companyBrand.name),callback:function ($$v) {_vm.$set(companyBrand, "name", $$v)},expression:"companyBrand.name"}})]}}],null,true)})],1),(index !== 0)?_c('div',{staticClass:"col-4 col-md-1 pt-0 pb-0 pr-1"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteBrand(index)}}},[_c('v-icon',{attrs:{"center":"","dark":"","size":"15"}},[_vm._v(" fa-trash ")])],1)],1):_vm._e()])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-0"},[_c('v-btn',{staticClass:"mr-2 text--primary text-none elevation-0",attrs:{"color":"secondary"},on:{"click":_vm.addBrand}},[_vm._v(" Agregar ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"primary"},on:{"click":_vm.saveStep4}},[(!_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Siguiente")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Guardar")]):_vm._e()]),_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"default"},on:{"click":function($event){return _vm.$emit('nextStep',3)}}},[_vm._v(" Anterior ")])],1)])],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }