var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Compañia de interés")])]),_c('v-card-text',[_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"Empresa","vid":"name","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Empresa*","required":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"countryId","name":"País","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.countries,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"País*"},on:{"change":_vm.onChangeCountry},model:{value:(_vm.model.countryId),callback:function ($$v) {_vm.$set(_vm.model, "countryId", $$v)},expression:"model.countryId"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"departmentId","name":"Departamento/estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.departments,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Departamento/estado*"},on:{"change":_vm.onChangeDepartment},model:{value:(_vm.model.departmentId),callback:function ($$v) {_vm.$set(_vm.model, "departmentId", $$v)},expression:"model.departmentId"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"cityId","name":"Ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.cities,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Ciudad*"},model:{value:(_vm.model.cityId),callback:function ($$v) {_vm.$set(_vm.model, "cityId", $$v)},expression:"model.cityId"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"Contacto","vid":"contact","rules":"max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Contacto","required":""},model:{value:(_vm.model.contact),callback:function ($$v) {_vm.$set(_vm.model, "contact", $$v)},expression:"model.contact"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"name":"Correo corporativo","vid":"email","rules":"max:80|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Correo corporativo","required":""},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Teléfono","vid":"contactPhone","rules":{phone: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"label":"Teléfono*","mode":"international","error-messages":errors,"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.contactPhone),callback:function ($$v) {_vm.$set(_vm.model, "contactPhone", $$v)},expression:"model.contactPhone"}})]}}])})],1),_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Extensión","vid":"contactPhoneExtension","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Extensión*","required":""},model:{value:(_vm.model.contactPhoneExtension),callback:function ($$v) {_vm.$set(_vm.model, "contactPhoneExtension", $$v)},expression:"model.contactPhoneExtension"}})]}}])})],1)])])])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }