var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form"},[(_vm.model.duplicatedInfo)?_c('div',[_c('v-alert',{attrs:{"type":"error"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" Tu compañía ya ha participado en ferias previas, hemos recopilado y precargado en este formulario la información más actualizada. Verifícala con cuidado y actualízala si es necesario. ")])])],1):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Representante Legal")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"legalRepresentativeName","name":"Nombre","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre*","required":""},model:{value:(_vm.model.legalRepresentativeName),callback:function ($$v) {_vm.$set(_vm.model, "legalRepresentativeName", $$v)},expression:"model.legalRepresentativeName"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"legalRepresentative.email","name":"Correo electrónico","rules":"required|max:255|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"label":"Correo electrónico*","required":""},model:{value:(_vm.model.legalRepresentativeEmail),callback:function ($$v) {_vm.$set(_vm.model, "legalRepresentativeEmail", $$v)},expression:"model.legalRepresentativeEmail"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"legalRepresentativeIdentificationType","name":"Tipo de identificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.documentTypes,"item-text":"description","item-value":"code","outlined":"","dense":"","label":"Tipo de identificación*"},model:{value:(_vm.model.legalRepresentativeIdentificationType),callback:function ($$v) {_vm.$set(_vm.model, "legalRepresentativeIdentificationType", $$v)},expression:"model.legalRepresentativeIdentificationType"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"legalRepresentativeIdentification","name":"Identificación","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Identificación*","required":""},model:{value:(_vm.model.legalRepresentativeIdentification),callback:function ($$v) {_vm.$set(_vm.model, "legalRepresentativeIdentification", $$v)},expression:"model.legalRepresentativeIdentification"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"vid":"legalRepresentativePhone","name":"Teléfono","rules":{required:true, phone: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{staticClass:"flex-wrap",attrs:{"label":"Teléfono*","mode":"international","inputOptions":{showDialCode:false},"error-messages":errors,"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.legalRepresentativePhone),callback:function ($$v) {_vm.$set(_vm.model, "legalRepresentativePhone", $$v)},expression:"model.legalRepresentativePhone"}})]}}])})],1),_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"vid":"legalRepresentativePhoneExtension","name":"Extensión","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Extensión"},model:{value:(_vm.model.legalRepresentativePhoneExtension),callback:function ($$v) {_vm.$set(_vm.model, "legalRepresentativePhoneExtension", $$v)},expression:"model.legalRepresentativePhoneExtension"}})]}}])})],1)])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Gerente de mercadeo")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"vid":"CMOName","name":"Nombre","rules":"max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre","required":""},model:{value:(_vm.model.CMOName),callback:function ($$v) {_vm.$set(_vm.model, "CMOName", $$v)},expression:"model.CMOName"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"vid":"CMOPositionDetail","name":"Cargo","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Cargo","required":""},model:{value:(_vm.model.CMOPositionDetail),callback:function ($$v) {_vm.$set(_vm.model, "CMOPositionDetail", $$v)},expression:"model.CMOPositionDetail"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"vid":"CMOEmail","name":"Correo electrónico","rules":"max:255|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"label":"Correo electrónico","required":""},model:{value:(_vm.model.CMOEmail),callback:function ($$v) {_vm.$set(_vm.model, "CMOEmail", $$v)},expression:"model.CMOEmail"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"vid":"CMOPhone","rules":{phone:true},"name":"Teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"label":"Teléfono","mode":"international","error-messages":errors,"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.CMOPhone),callback:function ($$v) {_vm.$set(_vm.model, "CMOPhone", $$v)},expression:"model.CMOPhone"}})]}}])})],1),_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"vid":"CMOPhoneExtension","name":"Extensión","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Extensión"},model:{value:(_vm.model.CMOPhoneExtension),callback:function ($$v) {_vm.$set(_vm.model, "CMOPhoneExtension", $$v)},expression:"model.CMOPhoneExtension"}})]}}])})],1)])])])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Área de compras")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"vid":"shoppingAreaName","name":"Nombre","rules":"max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre","required":""},model:{value:(_vm.model.shoppingAreaName),callback:function ($$v) {_vm.$set(_vm.model, "shoppingAreaName", $$v)},expression:"model.shoppingAreaName"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"vid":"shoppingAreaPositionDetail","name":"Cargo","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Cargo","required":""},model:{value:(_vm.model.shoppingAreaPositionDetail),callback:function ($$v) {_vm.$set(_vm.model, "shoppingAreaPositionDetail", $$v)},expression:"model.shoppingAreaPositionDetail"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"vid":"shoppingAreaEmail","name":"Correo electrónico","rules":"max:255|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"label":"Correo electrónico","required":""},model:{value:(_vm.model.shoppingAreaEmail),callback:function ($$v) {_vm.$set(_vm.model, "shoppingAreaEmail", $$v)},expression:"model.shoppingAreaEmail"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"vid":"shoppingAreaPhone","rules":{phone:true},"name":"Teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{staticClass:"flex-wrap",attrs:{"label":"Teléfono","mode":"international","error-messages":errors,"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.shoppingAreaPhone),callback:function ($$v) {_vm.$set(_vm.model, "shoppingAreaPhone", $$v)},expression:"model.shoppingAreaPhone"}})]}}])})],1),_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"vid":"shoppingAreaPhoneExtension","name":"Extensión","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Extensión"},model:{value:(_vm.model.shoppingAreaPhoneExtension),callback:function ($$v) {_vm.$set(_vm.model, "shoppingAreaPhoneExtension", $$v)},expression:"model.shoppingAreaPhoneExtension"}})]}}])})],1)])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"primary"},on:{"click":_vm.saveStep2}},[(!_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Siguiente")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Guardar")]):_vm._e()]),_c('v-btn',{staticClass:"mr-2 text-none elevation-0 ",attrs:{"color":"default"},on:{"click":function($event){return _vm.$emit('nextStep',1)}}},[_vm._v(" Anterior ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }