var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-0 pb-0"},[_c('p',{staticClass:"font-bold"},[_vm._v("Su empresa ha participado en ruedas de negocios nacionales?*.")]),_c('v-container',{staticClass:"px-0 pt-0 pb-0 ml-2",attrs:{"fluid":""}},[_c('validation-provider',{attrs:{"vid":"nationalBusinessConferenceParticipation","name":"participación rueda negocios nacionales","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"column":"","error-messages":errors},model:{value:(_vm.model.nationalBusinessConferenceParticipation),callback:function ($$v) {_vm.$set(_vm.model, "nationalBusinessConferenceParticipation", $$v)},expression:"model.nationalBusinessConferenceParticipation"}},[_c('v-radio',{attrs:{"color":"primary","label":"Si","value":1}}),_c('v-radio',{attrs:{"label":"No","value":0}})],1)]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-0 pb-0"},[_c('p',{staticClass:"font-bold"},[_vm._v("Su empresa ha participado en ruedas de negocios internacionales?*.")]),_c('v-container',{staticClass:"px-0 pt-0 pb-0 ml-2",attrs:{"fluid":""}},[_c('validation-provider',{attrs:{"vid":"internationalBusinessConferenceParticipation","name":"participación rueda negocios internacionales","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"column":"","error-messages":errors},model:{value:(_vm.model.internationalBusinessConferenceParticipation),callback:function ($$v) {_vm.$set(_vm.model, "internationalBusinessConferenceParticipation", $$v)},expression:"model.internationalBusinessConferenceParticipation"}},[_c('v-radio',{attrs:{"label":"Si","value":1}}),_c('v-radio',{attrs:{"label":"No","value":0}})],1)]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-0 pb-0"},[_c('p',{staticClass:"font-bold"},[_vm._v("Su empresa realiza operaciones de:")]),_c('v-checkbox',{staticClass:"ml-2 pt-0 pb-0",attrs:{"color":"primary","label":'Importación'},model:{value:(_vm.model.imports),callback:function ($$v) {_vm.$set(_vm.model, "imports", $$v)},expression:"model.imports"}}),_c('v-checkbox',{staticClass:"ml-2 pt-0 pb-0",attrs:{"color":"primary","label":'Exportación'},on:{"change":_vm.onExports},model:{value:(_vm.model.exports),callback:function ($$v) {_vm.$set(_vm.model, "exports", $$v)},expression:"model.exports"}})],1)]),(_vm.model.exports)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"font-bold"},[_vm._v("Ingrese los paises de exportacion y porcentajes (máximo 10).")]),_vm._l((_vm.model.exportCountries),function(exportCountry,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-8 col-md-5 pt-0 pb-0"},[_c('validation-provider',{attrs:{"name":"Porcentaje","vid":"company","rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Porcentaje","required":""},model:{value:(exportCountry.percentage),callback:function ($$v) {_vm.$set(exportCountry, "percentage", $$v)},expression:"exportCountry.percentage"}})]}}],null,true)})],1),_c('div',{staticClass:"col-8 col-md-5 pt-0 pb-0"},[_c('validation-provider',{attrs:{"vid":"countries","name":"País","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.countries,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"País*"},model:{value:(exportCountry.countryId),callback:function ($$v) {_vm.$set(exportCountry, "countryId", $$v)},expression:"exportCountry.countryId"}})]}}],null,true)})],1),(index !== 0)?_c('div',{staticClass:"col-4 col-md-2 pt-0 pb-0 pr-1"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteCountry(index)}}},[_c('v-icon',{attrs:{"center":"","dark":"","size":"15"}},[_vm._v(" fa-trash ")])],1)],1):_vm._e()])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-0"},[_c('v-btn',{staticClass:"mr-2 text--primary elevation-0 text-none",attrs:{"color":"secondary"},on:{"click":_vm.addCountry}},[_vm._v(" Agregar ")])],1)])],2)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-0 pb-0"},[_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"primary"},on:{"click":_vm.saveStep11}},[_vm._v(" Guardar ")]),_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"default"},on:{"click":function($event){return _vm.$emit('nextStep',10)}}},[_vm._v(" Anterior ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }