var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Ventas "+_vm._s(Number(_vm.fair.year) - 2))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('validation-provider',{attrs:{"vid":"sales","name":"Ventas año anterior","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.saleRanges,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Ventas*"},model:{value:(_vm.model.lastYear.saleRangeId),callback:function ($$v) {_vm.$set(_vm.model.lastYear, "saleRangeId", $$v)},expression:"model.lastYear.saleRangeId"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('validation-provider',{attrs:{"vid":"lastYear.nationalPercentage","name":"Ventas Nacionales en porcentaje","rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Ventas Nacionales en porcentaje*","required":""},on:{"input":_vm.calculatePercentageLastYear},model:{value:(_vm.model.lastYear.nationalPercentage),callback:function ($$v) {_vm.$set(_vm.model.lastYear, "nationalPercentage", $$v)},expression:"model.lastYear.nationalPercentage"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('validation-provider',{attrs:{"vid":"lastYear.internationalPercentage","name":"Ventas Internacionales en porcentaje","rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","disabled":"","error-messages":errors,"label":"Ventas Internacionales en porcentaje*","required":""},model:{value:(_vm.model.lastYear.internationalPercentage),callback:function ($$v) {_vm.$set(_vm.model.lastYear, "internationalPercentage", $$v)},expression:"model.lastYear.internationalPercentage"}})]}}])})],1)])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Ventas "+_vm._s(Number(_vm.fair.year) - 1))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('validation-provider',{attrs:{"vid":"sales","name":"Ventas año actual","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.saleRanges,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Ventas*"},model:{value:(_vm.model.currentYear.saleRangeId),callback:function ($$v) {_vm.$set(_vm.model.currentYear, "saleRangeId", $$v)},expression:"model.currentYear.saleRangeId"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('validation-provider',{attrs:{"vid":"currentYear.nationalPercentage","name":"Ventas Nacionales en porcentaje","rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Ventas Nacionales en porcentaje*","required":""},on:{"input":_vm.calculatePercentageCurrentYear},model:{value:(_vm.model.currentYear.nationalPercentage),callback:function ($$v) {_vm.$set(_vm.model.currentYear, "nationalPercentage", $$v)},expression:"model.currentYear.nationalPercentage"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('validation-provider',{attrs:{"vid":"currentYear.internationalPercentage","name":"Ventas Internacionales en porcentaje","rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","disabled":"","error-messages":errors,"label":"Ventas Internacionales en porcentaje*","required":""},model:{value:(_vm.model.currentYear.internationalPercentage),callback:function ($$v) {_vm.$set(_vm.model.currentYear, "internationalPercentage", $$v)},expression:"model.currentYear.internationalPercentage"}})]}}])})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Ventas Proyectadas "+_vm._s(_vm.fair.year))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"sales","name":"Ventas año posterior","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.saleRanges,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Ventas*"},model:{value:(_vm.model.nextYear.saleRangeId),callback:function ($$v) {_vm.$set(_vm.model.nextYear, "saleRangeId", $$v)},expression:"model.nextYear.saleRangeId"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"nextYear.nationalPercentage","name":"Ventas Nacionales en porcentaje","rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Ventas Nacionales en porcentaje*","required":""},on:{"input":_vm.calculatePercentageNextYear},model:{value:(_vm.model.nextYear.nationalPercentage),callback:function ($$v) {_vm.$set(_vm.model.nextYear, "nationalPercentage", $$v)},expression:"model.nextYear.nationalPercentage"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"nextYear.internationalPercentage","name":"Ventas Internacionales en porcentaje","rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","disabled":"","error-messages":errors,"label":"Ventas Internacionales en porcentaje*","required":""},model:{value:(_vm.model.nextYear.internationalPercentage),callback:function ($$v) {_vm.$set(_vm.model.nextYear, "internationalPercentage", $$v)},expression:"model.nextYear.internationalPercentage"}})]}}])})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"primary"},on:{"click":_vm.saveStep8}},[(!_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Siguiente")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Guardar")]):_vm._e()]),_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"default"},on:{"click":function($event){return _vm.$emit('nextStep',7)}}},[_vm._v(" Anterior ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }